import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Multi-monitor support for the InstarVision 2.6",
  "path": "/Frequently_Asked_Question/InstarVision_Windows_Multimonitor_Support/",
  "dateChanged": "2020-05-13",
  "author": "Mike Polinowski",
  "excerpt": "With the InstarVision 2.6 Windows software, right-clicking on the video leads to a full screen mode. If you have more than one monitor connected to your PC, the full screen will always open on the first monitor. Is it possible to run this full screen mode on one of the other monitors?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - Multi-monitor support for the InstarVision 2.6' dateChanged='2020-05-13' author='Mike Polinowski' tag='INSTAR IP Camera' description='With the InstarVision 2.6 Windows software, right-clicking on the video leads to a full screen mode. If you have more than one monitor connected to your PC, the full screen will always open on the first monitor. Is it possible to run this full screen mode on one of the other monitors?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/InstarVision_Windows_Multimonitor_Support/' locationFR='/fr/Frequently_Asked_Question/InstarVision_Windows_Multimonitor_Support/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "multi-monitor-support-for-the-instarvision-26",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#multi-monitor-support-for-the-instarvision-26",
        "aria-label": "multi monitor support for the instarvision 26 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multi-monitor support for the InstarVision 2.6`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: With the InstarVision 2.6 Windows software, right-clicking on the video leads to a full screen mode. If you have more than one monitor connected to your PC, the full screen will always open on the first monitor. Is it possible to run this full screen mode on one of the other monitors?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: If you want the full screen to run on the same monitor on which you have opened the software, you must activate multi-monitor support in the system settings:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cdb1911caf1131f450664030d037f371/2bef9/InstarVision2.6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC5ElEQVQozwXBXUwSAQAA4Fszkf/jjoO7Ew4BvUPgOLhfDo4/QcFQFuICf7ZWPbh+1larfKitJ98st1Zr1ayXcrMtW0PL/matXNZLJQ899NRWM635U0r11vcBJOn1UJSHpCiSIttImg6EWI7nJT8X8bEsEwqJcpQVFB/DB3mJCYZeLCy8evly5t697yurgM6oUUNggxFUwVAjZDIiJhNkUIP6WDKQ3sdGsjGGZrT2llA6opQ67X7vxKUrd27fHh8fX1pcBFwJiSt2Zga780fK3IH+PYO5Ul/ck5JGKsmJc0OjJ/cKEt3mZ+K56MlTZSmXOHP2fPXBg6mpqfszM4BNDCXyynBFOXE4fXi0PDRSzA9mHbLgdtvZAMExmMuNW3AHiqOuVofd0Xz02PHLVy6PjY3dnJwEUqLb4SeDUV8uGxg5mBo+lJP7kmhEwhDEYkEJm73dSRjMWIMe0kCoymC+eu1arba8tPTmw/v3ABVo9Qn+SNjZ3csNlsL7K3J/mXdz3kKH3FcsHBwoni6nWSVk6MoRxaIuwFYfP/lb/7NT/1ffqQO7QAi3Y542DKNJN0m0UzjpQen25rQspJVYRgx2ct5s3O/sSqWGCokE/3T+1vbW67WV51vrn4HG3Sq1RqfW6rVqXZNGt7tJ26DSw6DRZoFxBLYhJjtigs3WJjOGEkSTAZqdq/5Y+/Lt66f6ziaghywYhiNos81F6QxWDEVaCAyCcaMJ1hj0RhiCEQQ0oxoQNFosNhx5/Ojhr987q6s/Nzc2gXwhH+/uoeQONtljQ5lCR7gnHYbNJOh0eOIRK+1vNJtbPc5KX2exlCvnIwvP5jc2tzY21re3twHMRgiyiFJeJa4cH8j0Rtm9cblL8KqsFj6teMUgYNBrrRDLuGSZdrY1X5+8sfxx+e27d7VaDWCCnBiJcmJYicW7UrFsMpbNZJKJJCuFWVlmwzIrSowQ9vGCnxdplh+/cHFudvbu9PRstfofdIHUp1eFUA4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cdb1911caf1131f450664030d037f371/e4706/InstarVision2.6.avif 230w", "/en/static/cdb1911caf1131f450664030d037f371/d1af7/InstarVision2.6.avif 460w", "/en/static/cdb1911caf1131f450664030d037f371/7f308/InstarVision2.6.avif 920w", "/en/static/cdb1911caf1131f450664030d037f371/e3723/InstarVision2.6.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cdb1911caf1131f450664030d037f371/a0b58/InstarVision2.6.webp 230w", "/en/static/cdb1911caf1131f450664030d037f371/bc10c/InstarVision2.6.webp 460w", "/en/static/cdb1911caf1131f450664030d037f371/966d8/InstarVision2.6.webp 920w", "/en/static/cdb1911caf1131f450664030d037f371/a9a89/InstarVision2.6.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cdb1911caf1131f450664030d037f371/81c8e/InstarVision2.6.png 230w", "/en/static/cdb1911caf1131f450664030d037f371/08a84/InstarVision2.6.png 460w", "/en/static/cdb1911caf1131f450664030d037f371/c0255/InstarVision2.6.png 920w", "/en/static/cdb1911caf1131f450664030d037f371/2bef9/InstarVision2.6.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cdb1911caf1131f450664030d037f371/c0255/InstarVision2.6.png",
              "alt": "Multimonitor Support für die InstarVision 2.6",
              "title": "Multimonitor Support für die InstarVision 2.6",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      